/**
 * This function converts the Banner data format obtained from the alumni/update service and converts it into the structure needed for the form
 * @param object data
 */
const fields = {
  first: 'First Name',
  last: 'Last Name',
  preferred: 'Preferred Salutation/Name',
  class: 'Graduation Year',
  address: 'Mailing Address',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  email: {
    block: 'Email Address(es)',
    email: 'Email Address',
    preferred: 'Preferred Email?'
  },
  phone: {
    block: 'Phone Number(s)',
    phone: 'Phone Number',
    code: 'Phone Type',
    preferred: 'Preferred Number?'
  },
  employer: 'Employer',
  position: 'Position/Title',
  church: 'Church',
  hall: {
    block: 'Hall(s)',
    hall: 'Hall',
    years: 'Years'
  },
  sport: {
    block: 'Sport(s)',
    sport: 'Sport',
    years: 'Years'
  },
  marital: 'Marital Status',
  spouse: {
    first: 'Spouse Name',
    class: 'Spouse Class Year',
    email: {
      block: 'Spouse Email Address(es)',
      email: 'Spouse Email Address',
      preferred: 'Preferred Email?'
    },
    phone: {
      block: 'Spouse Phone Number(s)',
      phone: 'Spouse Phone Number',
      code: 'Phone Type',
      preferred: 'Preferred Number?'
    }
  },
  weeScots: 'I have children (wee Scots)',
  child: {
    block: 'Children',
    child: 'Child\'s Name',
    birthDate: 'Birth Date'
  }
}
const revFields = {
  'First Name': 'first',
  'Last Name': 'last',
  'Preferred Salutation/Name': 'preferred',
  'Graduation Year': 'class',
  'Mailing Address': 'address',
  'City': 'city',
  'State': 'state',
  'Zip': 'zip',
  'Email Address(es)': {
    _field: 'email',
    'Email Address': 'email',
    'Preferred Email?': 'preferred'
  },
  'Phone Number(s)': {
    _field: 'phone',
    'Phone Number': 'phone',
    'Phone Type': 'code',
    'Preferred Number?': 'preferred'
  },
  'Employer': 'employer',
  'Position/Title': 'position',
  'Church': 'church',
  'Hall(s)': {
    _field: 'hall',
    'Hall': 'hall',
    'Years': 'years'
  },
  'Sport(s)': {
    _field: 'sport',
    'Sport': 'sport',
    'Years': 'years'
  },
  'Marital Status': 'marital',
  'Spouse Name': 'spouse.first',
  'Spouse First Name': 'spouse.first',
  'Spouse Maiden Name': 'spouse.maiden',
  'Spouse Class Year': 'spouse.class',
  'Spouse Email Address(es)': {
    _field: 'spouse.email',
    'Spouse Email Address': 'email',
    'Preferred Email?': 'preferred'
  },
  'Spouse Phone Number(s)': {
    _field: 'spouse.phone',
    'Spouse Phone Number': 'phone',
    'Phone Type': 'code',
    'Preferred Number?': 'preferred'
  },
  'I have children (wee Scots)': 'weeScots',
  'Children': {
    _field: 'child',
    'Child\'s Name': 'child',
    'Birth Date': 'birthDate'
  }
}
exports.dataMap = function (data) {
  const obj = {}
  for (const dataField in fields) {
    if (!(dataField in data)) continue
    const formField = fields[dataField]
    if (typeof (formField) === 'object') {
      if ('block' in formField) {
        obj[formField.block] = parseBlock(fields[dataField], data[dataField])
      } else {
        for (const subField in formField) {
          if (typeof (formField[subField]) === 'object') {
            obj[formField[subField].block] = parseBlock(formField[subField], data[dataField][subField])
          } else {
            if (dataField === 'church') obj[formField[subField]] = data[dataField][subField] + ''
            else obj[formField[subField]] = data[dataField][subField]
          }
        }
      }
    } else {
      obj[formField] = data[dataField]
    }
  }
  // console.log(obj)
  return obj
}

function parseBlock (block, data) {
  if (Array.isArray(data)) {
    const value = data.map((obj) => {
      const blockValues = {}
      for (const subField in block) {
        if (subField in obj) blockValues[block[subField]] = obj[subField]
      }
      return blockValues
    })

    return value
  }
}

exports.reverseMap = function (dataArr) {
  // Go through the submitted form data array and convert the data back to an object in the original object format (matching the fields object above)
  let obj = {}
  for (const { field, value } of dataArr) {
    if (value == null || value === '' || (Array.isArray(value) && value.length === 0)) continue
    if (field in revFields) {
      const mapField = revFields[field]
      if (typeof (revFields[field]) === 'object' && Array.isArray(value) && '_field' in mapField) {
        obj[mapField._field] = []
        for (let i = 0; i < value.length; i++) {
          const subObj = {}
          for (const { field: subField, value: subValue } of value[i]) {
            if (subField in mapField) {
              subObj[mapField[subField]] = subValue
            }
          }
          obj[mapField._field].push(subObj)
        }
      } else {
        obj[mapField] = value
      }
    }
  }
  for (const field in obj) {
    if (field.indexOf('.') > 0) {
      const temp = field.split('.')
      if (!(temp[0] in obj)) obj[temp[0]] = {}
      obj[temp[0]][temp[1]] = obj[field]
      delete obj[field]
    }
  }
  return obj
}

// Find anything in the updated data that does not match the original data
exports.findDiffs = function (original, updated) {
  const changes = {}
  for (const field in updated) {
    switch (field) {
      case 'email':
      case 'phone':
      case 'hall':
      case 'sport':
      case 'child':
        let arr = arrayDiff(field, updated[field], original[field])
        if (arr.length > 0) changes[field] = arr
        break
      case 'spouse':
        if (!('spouse' in original)) {
          changes.spouse = updated.spouse
        } else {
          if ('first' in updated.spouse && (!('first' in original.spouse) || updated.spouse.first !== original.spouse.first)) {
            if (!('spouse' in changes)) changes.spouse = {}
            changes.spouse.first = updated.spouse.first
          }
          if ('maiden' in updated.spouse && (!('maiden' in original.spouse) || original.spouse.maiden !== updated.spouse.maiden)) {
            if (!('spouse' in changes)) changes.spouse = {}
            changes.spouse.maiden = updated.spouse.maiden
          }
          if ('class' in updated.spouse && (!('class' in original.spouse) || updated.spouse.class !== original.spouse.class)) {
            if (!('spouse' in changes)) changes.spouse = {}
            changes.spouse.class = updated.spouse.class
          }
          if ('email' in updated.spouse && updated.spouse.email.length > 0) {
            if (!('email' in original.spouse)) {
              if (!('spouse' in changes)) changes.spouse = {}
              changes.spouse.email = updated.spouse.email
            } else {
              let arr = arrayDiff('email', updated.spouse.email, original.spouse.email)
              if (arr.length > 0) {
                if (!('spouse' in changes)) changes.spouse = {}
                changes.spouse.email = arr
              }
            }
          }
          if ('phone' in updated.spouse && updated.spouse.phone.length > 0) {
            if (!('phone' in original.spouse)) {
              if (!('spouse' in changes)) changes.spouse = {}
              changes.spouse.phone = updated.spouse.phone
            } else {
              let arr = arrayDiff('phone', updated.spouse.phone, original.spouse.phone)
              if (arr.length > 0) {
                if (!('spouse' in changes)) changes.spouse = {}
                changes.spouse.phone = arr
              }
            }
          }
        }
        break
      case 'church':
        if ('id' in updated.church) {
          if (!('id' in original.church) || original.church.id !== updated.church.id) {
            changes.church = updated.church
          }
        } else {
          changes.church = updated.church
        }
        break
      default:
        if (!(field in original) || original[field] !== updated[field]) {
          changes[field] = updated[field]
        }
        break
    }
  }
  return changes
}

function arrayDiff (field, updated, original) {
  let arr = []
  for (let i = 0; i < updated.length; i++) {
    let matched = false
    for (let j = 0; j < original.length; j++) {
      if (updated[i][field] === original[j][field]) {
        // Matched
        matched = true
        // Check to see if anything else changed
        for (const subfield in updated[i]) {
          if (updated[i][subfield] !== original[j][subfield]) {
            // Diff; add as an update
            arr.push({ action: 'upd', original: original[j], updated: updated[i] })
            break
          }
        }
        break
      }
    }
    if (!matched) {
      arr.push({ action: 'add', updated: updated[i] })
    }
  }

  for (let j = 0; j < original.length; j++) {
    let matched = false
    for (let i = 0; i < updated.length; i++) {
      if (original[j][field] === updated[i][field]) {
        // Matched
        matched = true
        break
      }
    }
    if (!matched) {
      arr.push({ action: 'rem', original: original[j] })
    }
  }
  return arr
}
