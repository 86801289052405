<template>
  <v-container grid-list-md>
    <v-card style="margin-bottom:5em">
      <v-card-title>Alumni Information and News Update</v-card-title>
      <v-card-text v-if="isSubmitting">
        <p v-if="isUploading">Uploading your photo. Depending on the size of the file and your internet speed, this could take a minute.</p>
        <p v-else>Processing and saving your form submission data. This should only take a few seconds...</p>
      </v-card-text>
      <v-card-text v-else-if="submitted">
        <p>Thank you for submitting your updated information!</p>
        <p v-if="includedViewUpdate">Thank you for submitting an update to <i>View</i>. Each submission is reviewed and might be edited before publication. If you did not include a photo but would like to, you can send a high-res photo (1MB or larger) to <a href="mailto:alumni@covenant.edu" target="_blank">alumni@covenant.edu</a> to accompany your update or you can reload this page (or click the link in the email again) and add a photo to your submission.</p>
      </v-card-text>
      <portal-form
        v-else
        :info-card="revision.infoCard"
        :info-card-text="revision.infoCardText"
        :sections="revision.sections"
        :section-type="revision.sectionType"
        :init-form-data="initFormData"
        title=""
        @submit="saveSubmission"></portal-form>
    </v-card>
  </v-container>
</template>
<style>
.v-stepper__step__step .v-icon.v-icon {
  font-size: 1rem
}
</style>
<script>
import { ref, reactive, onMounted, watch } from '@vue/composition-api'
import axios from 'axios'
import { dataMap } from '@/components/alumni/dataMap.js'
// import { getDiff } from '../../helpers/diffs'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    email: String
  },
  components: {
    PortalForm: () => import('@/components/forms/Form')
  },
  setup (props, { root, emit }) {
    const updateService = root.$feathers.service('alumni/update')

    const bannerData = reactive({})
    const initFormData = ref({})
    const verifyingEmail = ref(false)

    watch(() => props.id, () => {
      if (props.email && props.email !== '') {
        updateService.get(props.id, { query: { 'email.email': props.email.toLowerCase() } }).catch((e) => {
          emit('emailError')
        }).then((data) => {
          if (data == null) {
            emit('emailError')
          } else {
            initFormData.value = dataMap(data)
            for (let l in data) {
              if (l === '_id' || l === 'infoUpdate' || l === 'viewUpdate' || l === 'updatedAt' || l === 'createdAt') continue
              if (typeof data[l] === 'object') {
                if (Array.isArray(data[l])) {
                  bannerData[l] = Object.assign([], data[l])
                } else {
                  bannerData[l] = Object.assign({}, data[l])
                }
              } else {
                bannerData[l] = data[l]
              }
            }
          }
        })
      }
    })

    const revision = ref({})
    onMounted(() => {
      root.$feathers.service('forms/base').get('5eb95c6f1a7336683862e49f').then((data) => {
        root.$feathers.service('forms/revision').get(data.revisions.active).then((data) => {
          revision.value = data
        })
      })
    })

    const isUploading = ref(false)
    async function uploadFile (name, file) {
      isUploading.value = true
      let formData = new FormData()
      let dt = new Date()
      formData.append('filename', bannerData.bannerId + ' - ' + name + ' - ' + dt.toISOString().substring(0, 10))
      formData.append('file', file)
      formData.append('contentType', file.type)
      formData.append('folder', '1xIAszyvf-9d5EOo4gtlw8YUmZHqf06Wq')
      try {
        const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
        let res = await axios.post(basePath + '/service/drive-upload', formData)
        // console.log(res)
        return res.data.id
      } catch (e) {
        let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error uploading image: ' + e }
        root.$store.dispatch('main/snackbar', snackbar)
        isUploading.value = false
      }
    }

    const includedViewUpdate = ref(false)
    const submitted = ref(false)
    const isSubmitting = ref(false)
    async function saveSubmission (formData) {
      const name = formData['First Name'] + ' ' + formData['Last Name'] + ' ' + formData['Graduation Year']
      const data = {}
      const dataArr = []
      for (let field in formData) {
        let value = formData[field]
        if (field === 'New Field' || field === 'Photo to include with update (optional)' || value == null) continue
        if (Array.isArray(value) && typeof (value[0]) === 'object') {
          value = value.map((obj) => {
            let arr = []
            for (let field in obj) {
              arr.push({ field, value: obj[field] })
            }
            return arr
          })
        }
        dataArr.push({ field, value })
      }
      isSubmitting.value = true
      // The View updates (if they included one) will be pushed onto the viewUpdates array
      //    (this is the list of pending View updates)
      if (formData['You may include an update about me in the View magazine']) {
        const photo = await uploadFile(name, formData['Photo to include with update (optional)'])
        const update = formData['My View update']
        // Save the viewUpdate to the server, pushing it onto the updates array for the alumni object
        data.viewUpdate = {
          status: 'Pending',
          submitted: new Date(),
          update,
          photo
        }
        if (data['You may also include this update in the alumni news section of the alumni office\'s monthly e-newsletter']) {
          data.newsletterUpdate = {
            status: 'Pending',
            submitted: new Date(),
            update
          }
        }
        includedViewUpdate.value = true
      }
      let obj = {
        name,
        form: { // The details of the form for which this submission was made; the name is here in addition to the Forms-Base to make it simpler to load for the form submission history
          name: 'Alumni Information and News Update',
          base: '5eb95c6f1a7336683862e49f',
          revision: revision.value._id
        },
        status: 'Submitted',
        submittedDate: new Date(),
        data, // Will be used to store the view update and newsletter update data
        dataArr
      }

      // Save the data
      try {
        const { _id } = await root.$feathers.service('forms/submission').create(obj)
        await updateService.patch(props.id, { $push: { submissions: _id } })
      } catch (e) {
        let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error saving your update: ' + e }
        root.$store.dispatch('main/snackbar', snackbar)
        isSubmitting.value = false
        return
      }
      isSubmitting.value = false
      submitted.value = true
    }

    return {
      initFormData,
      verifyingEmail,
      revision,
      // updatedData,
      // fieldUpdated,
      isUploading,
      uploadFile,
      includedViewUpdate,
      submitted,
      isSubmitting,
      saveSubmission
    }
  }
}
</script>
